<template>
    <div id="zhu" class="chart"></div>
</template>

<script>
    export default {
        name: "chart_tuoyuan",
        data() {
            return {

            }
        },
        methods: {
            initCharts(data) {
                let dom = document.getElementById("zhu");
                let myChart = this.$charts.init(dom);
                let xData = ['今日已预约量', '明日已预约量'];
                let yData = data;
                let option = {
                    grid: {
                        top: '25%',
                        left: '-5%',
                        bottom: '8%',
                        right: '5%',
                        containLabel: true,
                    },
                    tooltip: {
                        show: true,
                    },
                    xAxis: [
                        {
                            type: 'category',
                            data: xData,
                            axisTick: {
                                alignWithLabel: true,
                            },
                            nameTextStyle: {
                                color: '#82b0ec',
                            },
                            axisLine: {
                                show: false,
                                lineStyle: {
                                    color: '#82b0ec',
                                },
                            },
                            axisLabel: {
                                textStyle: {
                                    color: '#fff',
                                },
                                margin: 30,
                            },
                        },
                    ],
                    yAxis: [
                        {
                            show: false,
                            type: 'value',
                            axisLabel: {
                                textStyle: {
                                    color: '#fff',
                                },
                            },
                            splitLine: {
                                lineStyle: {
                                    color: '#0c2c5a',
                                },
                            },
                            axisLine: {
                                show: false,
                            },
                        },
                    ],
                    series: [
                        {
                            name: '',
                            type: 'pictorialBar',
                            symbolSize: [60, 10],
                            symbolOffset: [0, -6], // 上部椭圆
                            symbolPosition: 'end',
                            z: 12,
                            // "barWidth": "0",
                            label: {
                                normal: {
                                    show: true,
                                    position: 'top',
                                    // "formatter": "{c}%"
                                    fontSize: 15,
                                    fontWeight: 'bold',
                                    color: '#006CFF',
                                },
                            },
                            color: '#006FFF',
                            data: yData,
                        },
                        {
                            name: '',
                            type: 'pictorialBar',
                            symbolSize: [60, 10],
                            symbolOffset: [0, 7], // 下部椭圆
                            // "barWidth": "20",
                            z: 12,
                            color: '#006FFF',
                            data: yData,
                        },
                        {
                            name: '',
                            type: 'pictorialBar',
                            symbolSize: function (d) {
                                return d > 0 ? [50, 15] : [0, 0]
                            },
                            symbolOffset: [0, 12], // 下部内环
                            z: 10,
                            itemStyle: {
                                normal: {
                                    color: 'transparent',
                                    borderColor: '#2EA9E5',
                                    borderType: 'solid',
                                    borderWidth: 1,
                                },
                            },
                            data: yData,
                        },
                        {
                            name: '',
                            type: 'pictorialBar',
                            symbolSize: function (d) {
                                return d > 0 ? [80, 20] : ""
                            },
                            symbolOffset: [0, 18], // 下部外环
                            z: 10,
                            itemStyle: {
                                normal: {
                                    color: '#0000A6',
                                    borderColor: '#0000D8',
                                    borderType: 'solid',
                                    borderWidth: 2,
                                },
                            },
                            data: yData,
                        },
                        {
                            type: 'bar',
                            //silent: true,
                            barWidth: '60',
                            barGap: '10%', // Make series be overlap
                            barCateGoryGap: '10%',
                            itemStyle: {
                                normal: {
                                    color: new this.$charts.graphic.LinearGradient(0, 0, 0, 0.7, [
                                        {
                                            offset: 0,
                                            color: '#003BFF',
                                        },
                                        {
                                            offset: 1,
                                            color: '#0000B6',
                                        },
                                    ]),
                                    opacity: 0.8,
                                },
                            },
                            data: yData,
                        },
                    ],
                };
                myChart.setOption(option);
            }
        }
    }
</script>

<style scoped>

</style>
