<template>
    <section style="position:relative;width: 100%;height: 100%;">
        <div style="display: flex;height: 100%;justify-content: space-between;width: 95%">
            <div id="man"></div>
            <div id="woman"></div>
        </div>
        <div class="block_content_img">
            <div class="block_content_img_item">
                <p>{{ baifenbi1 }}</p>
                <img src="../assets/man2.png"/>
            </div>
            <div class="block_content_img_item">
                <p>{{ baifenbi2 }}</p>
                <img src="../assets/woman2.png"/>
            </div>
        </div>
        <div class="bottom">
            <div><div class="arc" style="background-color: #32ADFB"></div><span>男性</span></div>
            <div><div class="arc"></div><span>女性</span></div>
        </div>
    </section>
</template>

<script>
    export default {
        props: ['sexdata'],
        name: "SexProportion",
        data() {
            return {
                baifenbi1: "",
                baifenbi2: "",
                nan: false,
                nv: false,
            }
        },
        methods: {
            getData(data) {
                let total = parseInt(data[0]) + parseInt(data[1]);
                let num1 = ((data[0]/total)*100).toFixed(0);
                let num2 = ((data[1]/total)*100).toFixed(0);
                num1 = isNaN(num1) ? 0 : num1;
                num2 = isNaN(num2) ? 0 : num2;
                let data1 = [
                    {
                        value: data[1],
                    },
                    {
                        value: data[0],
                    }
                ];
                let data2 = [
                    {
                        value: data[0]
                    },
                    {
                        value: data[1]
                    }
                ];
                this.baifenbi1 = num1 + '%';
                this.baifenbi2 = num2 + '%';
                this.draw4(data1);
                this.draw5(data2);
            },
            draw4(data){
                let dom = document.getElementById("man");
                let myChart = this.$charts.init(dom);
                myChart.clear()
                myChart.setOption({
                    color: ['#061D96', '#32ADFB'],
                    series: [
                        {
                            type: 'pie',
                            radius: ['64%', '80%'],
                            label: {
                                show: false,
                            },
                            data
                        }
                    ]
                })
            },
            draw5(data){
                let dom = document.getElementById("woman");
                let myChart = this.$charts.init(dom);
                myChart.clear()
                myChart.setOption({
                    color: ['#061D96', 'rgb(255,148,220)'],
                    series: [
                        {
                            type: 'pie',
                            radius: ['64%', '80%'],
                            label: {
                                show: false
                            },
                            data
                        }
                    ]
                })
            },
        }
    }
</script>

<style scoped lang="scss">
    #man {
        height: 100%;
        width: 44%;
    }
    #woman {
        height: 100%;
        width: 44%;
    }
    .block_content_img {
        width: 95%;
        height: 100%;
        position: absolute;
        top: 0;
        z-index: 100;
        display: flex;
        justify-content: space-between;
        .block_content_img_item {
            width: 44%;
            height: 100%;
            position: relative;

            p {
                position: absolute;
                top: 32%;
                left: 50%;
                transform: translateX(-50%);
                font-size: 135%;
                color: white;
                font-weight: bold;
            }

            img {
                width: 23%;
                height: 20%;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translateX(-50%);
            }
        }

    }
    .bottom {
        width: 30%;
        position: absolute;
        bottom: 8%;
        left: -4%;
        right: 0;
        margin: auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 12px;
        color: #fff;
        .arc {
            display: inline-block;
            width: 11px;
            height: 11px;
            border-radius: 50%;
            margin-right: 5px;
            background-color: rgb(255,148,220);
        }
    }
</style>
