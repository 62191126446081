<template>
    <div id="dengji_list" class="chart"></div>
</template>

<script>
    export default {
        name: "registration_list",
        data() {
            return {
                timer: null,
                page: 0,
                currnet: 0,
            }
        },
        methods: {
            initList(salvProName, salvProValue) {
                clearInterval(this.timer);
                let vm = this;
                this.page = salvProName.length % 20 === 0 ? salvProName.length / 20 : Math.ceil(salvProName.length / 20);
                var salvProMax =[];//背景按最大值
                for (let i = 0; i < salvProValue.length; i++) {
                    salvProMax.push(salvProValue[0])
                }
                let option = {
                    grid: {
                        left: '2%',
                        right: '2%',
                        bottom: '-2%',
                        top: '2%',
                        containLabel: true
                    },
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'none'
                        },
                        formatter: function(params) {
                            return params[0].name  + ' : ' + params[0].value
                        }
                    },
                    xAxis: {
                        show: false,
                        type: 'value'
                    },
                    yAxis: [{
                        name: "ming",
                        type: 'category',
                        inverse: true,
                        axisLabel: {
                            show: true,
                            textStyle: {
                                color: '#fff'
                            },
                        },
                        splitLine: {
                            show: false
                        },
                        axisTick: {
                            show: false
                        },
                        axisLine: {
                            show: false
                        },
                        data: salvProName.slice(0, 20)
                    }, {
                        name: "value",
                        type: 'category',
                        inverse: true,
                        axisTick: 'none',
                        axisLine: 'none',
                        show: true,
                        axisLabel: {
                            textStyle: {
                                color: '#ffffff',
                                fontSize: '12'
                            },
                        },
                        data:salvProValue.slice(0, 20)
                    }],
                    series: [{
                        name: '值',
                        type: 'bar',
                        zlevel: 1,
                        itemStyle: {
                            normal: {
                                barBorderRadius: 30,
                                color: new this.$charts.graphic.LinearGradient(0, 0, 1, 0, [{
                                    offset: 0,
                                    color: 'rgb(57,89,255,1)'
                                }, {
                                    offset: 1,
                                    color: 'rgb(46,200,207,1)'
                                }]),
                            },
                        },
                        barWidth: 7,
                        data: salvProValue.slice(0, 20)
                    },
                        {
                            name: '背景',
                            type: 'bar',
                            barWidth: 7,
                            barGap: '-100%',
                            data: salvProMax,
                            itemStyle: {
                                normal: {
                                    color: 'rgba(24,31,68,1)',
                                    barBorderRadius: 30,
                                }
                            },
                        },
                    ]
                };
                let myEcharts = this.$charts.init(document.getElementById("dengji_list"));
                myEcharts.setOption(option);
                vm.timer = setInterval(() => {
                    vm.currnet = vm.currnet + 1;
                    if(vm.currnet >= vm.page) {
                        vm.currnet = 0;
                    }
                    let ydata = salvProName.slice(vm.currnet * 20, (vm.currnet + 1) * 20);
                    let zhi = salvProValue.slice(vm.currnet * 20, (vm.currnet + 1) * 20);
                    option.yAxis.data = ydata;
                    option.series[0].data = zhi;
                    let salvProMax = [];
                    for (let i = 0; i < zhi.length; i++) {
                        salvProMax.push(zhi[0])
                    }
                    option.series[1].data = salvProMax;
                    myEcharts.setOption({
                        yAxis: [
                            {
                                name: "value",
                                data: zhi,
                            },
                            {
                                name: "ming",
                                data: ydata
                            }
                        ],
                        series: [
                            {
                              name: '值',
                              data: zhi,
                            },
                            {
                                name: "背景",
                                data: salvProMax,
                            }
                        ]
                    });
                }, 1000 * 10)
            }
        }
    }
</script>

<style scoped lang="scss">
.registration_list {
    width: 100%;
    height: 100%;
}
    /deep/ .unit-label {
        visibility: hidden;
    }
</style>
