<template>
    <div id="app">
        <dv-full-screen-container>
            <div class="big_bg">
                <div class="top">
                    <div class="left">
                        <div class="jingbao">
                            <img src="./assets/jingbao.png" alt="警报">
                            <div>实时预警</div>
                        </div>
                        <div class="leiji">
                            <div style="font-size: 21px;font-weight: bold;margin-bottom: 12px">累计全市红码拦截总人次</div>
                            <div class="num">
                                <div class="cardRed" v-for="i in red_num">{{ i }}</div>
                            </div>
                            <div class="info">
                                <dv-scroll-board v-if="config.data.length" :config="config"
                                                 style="width:100%;height:100%"/>
                                <div v-else>暂无</div>
                            </div>
                        </div>
                    </div>
                    <div class="middle">
                        <div class="current_time">
                            <span style="height: 39px;line-height: 39px">{{ moment().format('LL') }}</span><span
                                class="city">{{ jia_city[curIdx].city_name }}</span>
                        </div>
                        <dv-decoration-10 style="width:454px;height:5px;margin-top: 20px;margin-left: 14%"/>
                    </div>
                    <div class="right">
                        <!--            <iframe src="weather.html" scrolling="no" frameborder="0"></iframe>-->
                        <div class="-datav-com absolute iframe-1-1-7"
                             style="width: 100%; height: 100%; z-index: 0; transform: rotate(0deg); opacity: 1; pointer-events: none;display: flex">
                            <div class="-datav-wraper"
                                 style="width: 100%; height: 100%; z-index: 0; transform: rotate(0deg); opacity: 1; pointer-events: none;display: flex">
                                <div id="he-plugin-standard"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="statistical_chart">
                    <div class="city_bg">
                        <div class="city_item" @click="changeCity(item,i)" :class="curIdx === i? 'selected' : ''"
                             v-for="(item, i) in jia_city">
                            <div class="img">
                                <img :src="item.city_img" alt="">
                            </div>
                            <div class="city_name">{{ item.city_name }}</div>
                        </div>
                    </div>
                    <div class="charts">
                        <div class="left">
                            <div class="l_top">
                                <div class="qushi">
                                    <div class="title">
                                        <div>预约量比</div>
                                    </div>
                                    <div style="height: calc(100% - 42px);padding: 0 10px">
                                        <chart_tuoyuan ref="tuoyuan"/>
                                    </div>
                                </div>
                                <div class="sex">
                                    <div class="title">
                                        <div>性别比例</div>
                                    </div>
                                    <div style="height: calc(100% - 42px);padding: 0 40px">
                                        <SexProportion ref="sex"/>
                                    </div>
                                </div>
                            </div>
                            <div class="l_bom">
                                <div class="title">
                                    <div>访客登记TOP5</div>
                                </div>
                                <fang_top5 ref="fang5"/>
                            </div>
                        </div>
                        <div class="middle">
                            <div class="title">
                                <div>实时预约</div>
                            </div>
                            <div style="height: calc(100% - 42px);padding: 0 20px">
                                <div style="display: flex;margin-bottom: 20px;">
                                    <div class="inp" @click="showInp=true">
                                        请选择时间段后输入身份证或手机号
                                    </div>
                                    <!--                  <div class="btn">搜索</div>-->
                                </div>
                                <real_time ref="real_time"/>
                            </div>
                        </div>
                        <div class="right">
                            <div style="height: 100%;">
                                <div class="title">
                                    <div>文娱登记列表</div>
                                </div>
                                <div style="height: calc(100% - 42px);padding: 0 20px">
                                    <registration_list ref="changsuo_deng"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <dv-loading v-show="loading"/>
            </div>
            <Modal v-model="showInp" title="人员信息" loading footer-hide class-name="vertical-center-modal">
                <Form ref="form" :model="tiaojian" :rules="rules">
                    <FormItem prop="time">
                        <DatePicker v-model="tiaojian.time" type="daterange" format="yyyy-MM-dd" placement="bottom-end"
                                    placeholder="Select date" style="width: 100%"/>
                    </FormItem>
                    <FormItem prop="cardNum">
                        <Input type="text" v-model="tiaojian.cardNum" placeholder="请输入身份证号">
                            <Icon type="ios-card" slot="prepend"/>
                        </Input>
                    </FormItem>
                    <FormItem prop="passWord">
                        <Input type="password" :password="true" v-model="tiaojian.passWord" placeholder="请输入密码">
                            <Icon type="ios-lock-outline" slot="prepend"/>
                        </Input>
                    </FormItem>
                    <FormItem>
                        <div style="display: inline-block;float: right">
                            <Button style="margin-right: 8px" @click="showInp=false">取消</Button>
                            <Button type="primary" @click="submit" :loading="btnLoading">查询</Button>
                        </div>
                    </FormItem>
                </Form>
            </Modal>
            <!--   人员轨迹显示   -->
            <Modal v-model="showInfo" :title="`人员轨迹信息 ( ${tiaojian.cardNum} )`" :closable="false" :mask-closable="false">
                <Table stripe :columns="columns1" :data="infoList"/>
                <div class="empty">

                </div>
                <div slot="footer" style="overflow: hidden">
                    <Button style="margin-right: 8px;float: right" type="primary" @click="reset">确认</Button>
                </div>
            </Modal>
        </dv-full-screen-container>
    </div>
</template>

<script>
    // import man from "./assets/man.png"
    // import wuman from "./assets/woman.png"
    import chart_tuoyuan from "./components/chart_tuoyuan";
    import SexProportion from "./components/SexProportion";
    import fang_top5 from "./components/fang_top5";
    import real_time from "./components/real_time";
    import registration_list from "./components/registration_list";
    import moment from "moment";
    import 'moment/locale/zh-cn'

    moment.locale('zh-cn')
    let jia_city = [
        {city_name: "嘉兴市", city_code: '3304', city_img: require("./assets/jiaxing.jpg")},
        {city_name: "南湖区", districtId: '330402', city_img: require("./assets/nanhu.png")},
        {city_name: "秀洲区", districtId: '330411', city_img: require("./assets/xiuzhou.png")},
        // {city_name:"经济技术开发区", districtId: '3304', city_img: require("./assets/jiaxing.jpg")},
        // {city_name:"港区", districtId: '3305', city_img: require("./assets/jiaxing.jpg")},
        {city_name: "嘉善县", districtId: '330421', city_img: require("./assets/jiashan.png")},
        {city_name: "海盐县", districtId: '330424', city_img: require("./assets/haiyan.png")},
        {city_name: "平湖市", districtId: '330482', city_img: require("./assets/pinghu.png")},
        {city_name: "海宁市", districtId: '330481', city_img: require("./assets/haining.png")},
        {city_name: "桐乡市", districtId: '330483', city_img: require("./assets/tongxaing.png")},
    ];
    // 身份证号校验
    const checkIdNum = (rule, value, callback) => {
        if (!value) {
            return callback(new Error('身份证不能为空'))
        }
        if (!/(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/.test(value)) {
            callback(new Error('请输入正确的二代身份证号码'))
        } else {
            callback()
        }
    }
    export default {
        name: 'App',
        components: {
            chart_tuoyuan,
            SexProportion,
            fang_top5,
            real_time,
            registration_list
        },
        data() {
            return {
                columns1: [
                    {
                        title: '场馆名称',
                        key: 'museumName'
                    },
                    {
                        title: '时间场次',
                        key: 'sessionTime'
                    },
                    {
                        title: '出行日期',
                        key: 'travelDate'
                    }
                ],
                btnLoading: false,
                moment,
                config: {
                    waitTime: 3000,
                    oddRowBGC: "transparent",
                    evenRowBGC: "transparent",
                    rowNum: 1,
                    data: []
                },
                showInp: false,
                showInfo: false,
                curIdx: 0,
                loading: false,
                jia_city,
                currentCity: '3304',
                red_num: '000000',
                districtId: null,
                userId: [10246, 10962, 10949],
                tiaojian: {
                    time: [],
                    cardNum: "",
                    passWord: ""
                },
                infoList: [],
                rules: {
                    time: [{required: true, type: "array", message: '请选择起始时间', trigger: 'change',fields: {
                        0: { type: 'date', required: true, message: '请选择开始日期' },
                        1: { type: 'date', required: true, message: '请选择结束日期' },
                      }}],
                    cardNum: [{
                        required: true,
                        message: "身份证号码不能为空",
                        trigger: "change"
                    }, {
                        validator: checkIdNum,
                        trigger: 'change'
                    }],
                    passWord: [{required: true, message: '请输入密码', trigger: 'change'}]
                }
            }
        },
        created() {

        },
        mounted() {
            this.$http("/api/jiaxin/getAbnormalHealthRecord", {cityId: this.currentCity}).then(res => {
                res.data = res.data.map(item => {
                    return {
                        // img: `<img src="${wuman}" style="width: 17px;color: #FA2828" />`,
                        cardNum: `<div style="width: 139px;color: #FA2828">${item.cardNum}</div>`,
                        gmtCrate: `<div style="width: 70px;color: #FA2828">${item.gmtCreate.split(" ")[1]}</div>`,
                        museumName: `<div style="width: 181px;color: #FA2828">${item.museumName}</div>`
                    }
                });
                this.config.data = res.data;
                this.config = {...this.config};
            });
            this.initCharts();
            this.redNum();
            setInterval(() => {
                this.redNum();
            }, 1000 * 60)
        },
        methods: {
            reset() {
                this.showInfo = false;
              this.$refs["form"].resetFields();
            },
            redNum() {
                this.$http("/api/jiaxin/getInterceptRedCodeNum", {cityId: this.currentCity}).then(res => {
                    let num = res.data.toString();
                    let ling = '';
                    for (let i = 0; i < 6 - num.length; i++) {
                        ling += '0';
                    }
                    this.red_num = ling + num;
                })
            },
            changeCity(item, i) {
                this.curIdx = i;
                this.districtId = item.districtId || null;
                this.initCharts();
            },
            initCharts() {
                this.tuoyuan();
                this.getSex();
                this.getfang5();
                this.changsuo_deng();
                this.time_deng();
            },
            getchengshi() {
                let {currentCity: cityId, districtId} = this;
                if (districtId) {
                    return {cityId, districtId};
                }
                return {cityId}
            },
            tuoyuan() {
                let data = this.getchengshi();
                this.$http("/api/jiaxin/getBookSituation", data, "post").then(res => {
                    let data = [res.data.todayBookNum, res.data.tomorrowBookNum];
                    this.$refs.tuoyuan.initCharts(data);
                })
            },
            // 性别比例
            getSex() {
                let data = this.getchengshi();
                this.$http("/api/jiaxin/getSexRatio", {...data, userId: this.userId}, "post").then(res => {
                    let data = [res.data.maleNum || 0, res.data.femaleNum || 0];
                    this.$refs.sex.getData(data);
                })
            },
            // 方可登记Top5
            getfang5() {
                let data = this.getchengshi();
                this.$http("/api/jiaxin/getVisitTop5", {...data, userId: this.userId}, "post").then(res => {
                    this.$refs.fang5.list = res.data;
                })
            },
            // 场所
            changsuo_deng() {
                let data = this.getchengshi();
                this.$http("/api/jiaxin/getVisitTop", {...data, userId: this.userId}, "post").then(res => {
                    let name = [];
                    let num = [];
                    for (let item of res.data) {
                        name.push(item.name);
                        num.push(item.num)
                    }
                    this.$refs.changsuo_deng.initList(name, num);
                })
            },
            // 实施登记
            time_deng() {
                let data = this.getchengshi();
                this.$http("/api/jiaxin/getRealtimeRegistration", {...data, userId: this.userId}, 'post').then(res => {
                    res.data = res.data.map(item => {
                        return {
                            gmtCrate: `<div style="width: 70px">${item.gmtCrate.split(" ")[1]}</div>`,
                            name: `<div style="width: 50px">${item.name}</div>`,
                            cardNum: `<div style="width: 139px">${item.cardNum}</div>`,
                            museumName: `<div style="width: 181px">${item.museumName}</div>`
                        }
                    });
                    this.$refs.real_time.setData(res.data);
                })
            },
            // 轨迹查询
            selectGuiji() {
                this.btnLoading = true;

                let {cardNum, time} = this.tiaojian;
                let beginDate = moment(time[0]).format("YYYY-MM-DD");
                let endDate = moment(time[1]).format("YYYY-MM-DD");

                let data = {
                    cardNum,
                    beginDate,
                    endDate
                };
                this.$http("/api/jiaxin/getUserTrajectoryByDashboard", data, "post").then(res => {
                    this.btnLoading = false;
                    this.showInp = false;
                    this.showInfo = true;
                    this.infoList = res.data;
                })
            },
            submit() {
                this.$refs['form'].validate((valid) => {
                    if (valid) {
                        if (this.tiaojian.passWord === "123456") {
                            this.selectGuiji();
                        } else {
                            this.$Message.error("密码错误!");
                        }
                    } else {
                      console.log(this.tiaojian);
                        this.$Message.error("请填写完整!");
                    }
                })
            }
        }

    }
</script>

<style lang="scss">
    .vertical-center-modal {
        display: flex;
        align-items: center;
        justify-content: center;

        .ivu-modal {
            top: 0;
        }
    }

    .chart {
        width: 100%;
        height: 100%;
    }

    * {
        margin: 0;
        box-sizing: border-box;
        padding: 0;
    }

    .big_bg {
        width: 100%;
        height: 100%;
        background-image: url("./assets/big_bg.jpg");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        padding: 0 45px;
    }

    .top {
        display: flex;

        .left {
            width: 31%;
            color: #CF0000;
            padding-top: 102px;
            display: flex;
            align-content: flex-end;

            .leiji {
                padding-bottom: 8px;
                text-align: center;
                flex: 1;

                .num {
                    display: flex;
                    position: relative;
                    justify-content: center;

                    .cardRed:last-child::after {
                        position: absolute;
                        content: "人次";
                        font-size: 19px;
                        color: #fff;
                        right: -47px;
                        bottom: 0;
                        height: 19px;
                        line-height: 19px;
                    }
                }

                .info {
                    padding: 0 22px;
                    display: flex;
                    align-items: center;
                    width: 100%;
                    margin-top: 41px;
                    height: 44px;
                    border: 1px solid #CF0000;
                    color: #FA2828;
                    font-size: 16px;

                    div {
                        span {
                            margin-right: 8px;
                        }
                    }
                }
            }

            .jingbao {
                text-align: center;
                font-size: 16px;
                display: flex;
                width: 94px;
                flex-wrap: wrap;
                flex-direction: column;
                justify-content: flex-end;
                font-weight: bold;

                img {
                    vertical-align: top;
                    width: 94px;
                    height: 94px;
                }
            }
        }

        .right {
            padding-top: 110px;
            display: flex;
            width: 30%;

            iframe {
                width: 100% !important;
                height: 100% !important;
                /*transform: scale(1.18, 1.045);*/
            }
        }

        .middle {
            display: flex;
            flex-wrap: wrap;
            padding-top: 175px;
            flex: 1;

            .current_time {
                width: 100%;
                display: flex;
                padding-left: 14%;
                font-size: 40px;
                font-weight: bold;
                color: #006CFF;

                .city {
                    display: inline-block;
                    width: 131px;
                    height: 43px;
                    background: #FFFFFF;
                    border-radius: 14px;
                    text-align: center;
                    line-height: 43px;
                    font-size: 27px;
                    margin-left: 30px;
                }
            }
        }
    }

    .cardRed {
        position: relative;
        background-color: #8C0513;
        color: #FEFEFE;
        font-size: 40px;
        text-align: center;
        height: 46px;
        line-height: 46px;
        width: 40px;
        margin-right: 5px;
    }

    .statistical_chart {
        margin-top: 18px;
        border: 1px solid rgba(22, 208, 255, .3);
        height: 762px;

        .city_bg {
            height: 212px;
            background: #122FA6;
            padding: 10px 70px;
            display: flex;
            justify-content: space-between;

            .city_item {
                cursor: pointer;
                width: calc(100% / 10);
                padding: 10px;
                display: flex;
                justify-content: center;
                align-content: space-between;
                flex-wrap: wrap;

                .city_name {
                    font-size: 18px;
                    color: rgba(255, 255, 255, .15);
                    margin-top: 14px;
                }

                .img {
                    width: 127px;
                    height: 127px;
                    border-radius: 17px;

                    img {
                        width: 100%;
                        height: 100%;
                        border-radius: 15px;
                    }
                }
            }
        }
    }

    .charts {
        height: 549px;
        padding: 25px 25px 15px;
        display: flex;
        justify-content: space-between;

        .left {
            width: 46%;
            height: 100%;

            .l_top {
                display: flex;

                .qushi {
                    width: 40%;
                    height: 100%;
                }

                .sex {
                    width: 60%;
                    height: 100%;
                }
            }

            & > div:first-child {
                margin-bottom: 15px;
            }

            & > div {
                height: calc(50% - 7.5px);
                border: 1px solid rgba(22, 208, 255, .3);
            }
        }

        .middle, .right {
            width: 26.25%;
            border: 1px solid rgba(22, 208, 255, .3);
        }
    }

    .selected {
        border-radius: 10px;
        background-color: #00278A;

        .city_name {
            transform: scale(1.15);
            font-weight: bold;
            color: #FFFFFF !important;
        }

        box-shadow: 1px 0px 45px 7px rgba(16, 108, 205, 1);
        -webkit-box-shadow: 1px 0px 45px 7px rgba(16, 108, 205, 1);
        -moz-box-shadow: 1px 0px 45px 7px rgba(16, 108, 205, 1);
    }

    .title {
        color: #fff;
        font-size: 18px;
        font-weight: bold;
        padding: 10px 0 10px 25px;

        div {
            height: 100%;
            position: relative;
        }

        div:after {
            content: " ";
            position: absolute;
            left: -10px;
            bottom: 5px;
            width: 4px;
            height: 18px;
            background: #01ABFF;
        }
    }

    .inp {
        width: 364px;
        height: 29px;
        background-color: #fff;
        line-height: 29px;
        padding-left: 5px;
        font-size: 12px;
        color: #333;
        border: 1px solid #E2E2E2;
        cursor: pointer;
    }

    .btn {
        width: 55px;
        height: 29px;
        background: #218FEC;
        border-radius: 7px;
        line-height: 29px;
        text-align: center;
        font-size: 16px;
        color: #FFFFFF;
        margin-left: 12px;
    }

    .empty {

    }
</style>
