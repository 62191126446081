<template>
    <section class="real_time">
        <dv-scroll-board :config="config" style="width:100%;height:100%" />
    </section>
</template>

<script>
    export default {
        name: "real_time",
        data() {
            return {
                i: 0,
                config: {
                    oddRowBGC: "transparent",
                    evenRowBGC: "transparent",
                    rowNum: 10,
                    data: []
                }
            }
        },
        methods: {
            setData(data) {
                this.config.data = data;
                this.config = { ...this.config };
            }
        }
    }
</script>

<style scoped lang="scss">
    .real_time {
        width: 100%;
        height: calc(100% - 48px);
        /*height: 100%;*/
        position: relative;
    }
    /deep/.dv-scroll-board {
        .ceil {
            padding: 0 !important;
            font-size: 13px;
        }
    }
</style>
