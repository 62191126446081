<template>
    <div class="list">
        <div class="item" v-for="(v,i) in list">
            <div class="bg">
                <img :src="static_img + v.pic" alt="">
            </div>
            <div class="changsuo">
                <p>{{ i + 1 }}. {{ v.name }}</p>
                <p>{{ v.num }}</p>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "fang_top5",
        data() {
            return {
                list: [],
                static_img: 'http://static.bowu66.com/',
            }
        },
        methods: {}
    }
</script>

<style scoped lang="scss">
.list {
    padding-top: 20px;
    display: flex;
    .item {
        width: 20%;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        .bg {
            margin-bottom: 16px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 108px;
            height: 108px;
            background: rgba(29,118,254, .3);
            border-radius: 50%;
            img {
                border: 3px solid #2878FB;
                width: 87px;
                height: 87px;
                border-radius: 50%;
            }
        }
        .changsuo {
            width: 100%;
            text-align: center;
            font-size: 14px;
            color: #FFFFFF;
            p {
                margin: 0 auto;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                width: 95%;
                margin-bottom: 5px;
            }
        }
    }
}
</style>
