import Vue from 'vue'
import App from './App.vue'
import dataV from '@jiaminghi/data-view'
import * as echarts from "echarts"
import axios from "axios"
import { Modal, Form, FormItem, Input, DatePicker, Icon, Button, Message, Table } from 'view-design';
import 'view-design/dist/styles/iview.css';
Vue.component('Modal', Modal);
Vue.component('Form', Form);
Vue.component('FormItem', FormItem);
Vue.component('Input', Input);
Vue.component('DatePicker', DatePicker);
Vue.component('Icon', Icon);
Vue.component('Button', Button);
Vue.component('Table', Table);
Vue.use(dataV);
Vue.prototype.$charts = echarts;
Vue.prototype.$Message = Message;
Vue.config.productionTip = false;
Vue.prototype.$http = (url = '', data = {}, type = 'get') => {
  return axios({
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      token: 'oGVbd4vIfn-oQ3F25YhqACrkGvc0'
    },
    method: type,
    // https://museum-voice.bowu66.com
    // http://192.168.124.78:19309/
    baseURL: 'https://museum-voice.bowu66.com',
    url,
    data: JSON.stringify(data),
    params: type.toLowerCase() === 'get' ? data : {}
  })
};
axios.interceptors.response.use((res) => {
  return res.data;
})
new Vue({
  render: h => h(App),
}).$mount('#app')
